export function FormField(props: {
  placeholder: string;
  onChange: (value: string) => void;
  value: string | undefined;
}) {
  return (
    <input
      type="text"
      placeholder={props.placeholder}
      onChange={(e) => props.onChange(e.currentTarget.value)}
      value={props.value}
      className="bg-transparent underline-none border-r-0 border-l-0 border-t-0 p-2 outline-none"
    />
  );
}
