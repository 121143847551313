import { modalIds } from '@/constants';
import ContactForm from './ContactForm';

export function BasicModal() {
  return (
    <dialog id={modalIds.basic} className="modal !border-0">
      <div className="modal-box !w-[80%] lg:!w-[91%]">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <ContactForm isModal />
      </div>
    </dialog>
  );
}
