import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import 'react-multi-carousel/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import '../styles/globals.css';

import AppWrapper from '../components/AppWrapper';
import CookieConsent from 'react-cookie-consent';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <AppWrapper>
        <Component {...pageProps} />
        <CookieConsent
          location="bottom"
          buttonText="Rozumiem"
          cookieName="SellWellCookie"
          style={{ background: '#2B373B' }}
          buttonStyle={{
            backgroundColor: '#141414',
            color: '#fff',
            fontSize: '14px',
          }}
          expires={150}
        >
          Ta strona korzysta z ciasteczek (cookies), dzięki którym nasz serwis
          może działać lepiej.
        </CookieConsent>
      </AppWrapper>
      <ToastContainer />
    </>
  );
}
