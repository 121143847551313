import { defaultStyles } from '@/utils/defaultStyles';
import dynamic from 'next/dynamic';

const Map = dynamic(() => import('@/components/Map'), {
  ssr: false,
});

function Footer() {
  const navStyle =
    'font-bold text-[25px] text-black hover:text-[#32A771] transition-all duration-300 cursor-pointer';

  return (
    <>
      <section className={`pb-6 md:py-24 ${defaultStyles.paddingBig}`}>
        <Map />
      </section>
      <footer
        className={`bg-[#F2F2F2] ${defaultStyles.paddingBig} pt-12 pb-6 flex flex-col gap-8`}
      >
        <div className="flex flex-col md:flex-row md:justify-between gap-8">
          <div className="flex flex-col gap-4">
            <img
              loading="lazy"
              src="/logo-black.svg"
              className="w-[200px]"
              alt="Sell-Well logo"
            />
            <div
              onClick={() => window.location.assign('/buy')}
              className={navStyle}
            >
              Kupno
            </div>
            <div
              onClick={() => window.location.assign('/sales')}
              className={navStyle}
            >
              Sprzedaż
            </div>
            <div
              onClick={() => window.location.assign('/lease')}
              className={navStyle}
            >
              Wynajem
            </div>
            <div
              onClick={() => window.location.assign('/invest')}
              className={navStyle}
            >
              Inwestycje
            </div>
          </div>
          <div className="flex flex-col gap-8 md:w-[60%]">
            <div className="flex flex-col gap-4">
              <div className="text-[16px] text-black">Kontakt</div>
              <div className="w-full h-[1px] bg-[#E0E0E0]" />
              <div className="flex flex-row gap-4">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_88_1403)">
                    <path
                      d="M1.21413 9.56642C4.00717 15.6532 8.9771 20.4925 15.1559 23.116L16.1464 23.5576C18.4124 24.5679 21.0764 23.8004 22.4585 21.7391L23.7528 19.8087C24.1736 19.1811 24.0455 18.3357 23.4577 17.8612L19.068 14.3178C18.4232 13.7973 17.4752 13.9187 16.982 14.5845L15.6241 16.4181C12.1396 14.6981 9.31056 11.8676 7.59176 8.38105L9.4241 7.02232C10.0897 6.5288 10.2108 5.58015 9.69066 4.93503L6.14929 0.542722C5.67511 -0.0453809 4.83053 -0.173667 4.20329 0.247154L2.26075 1.55044C0.187766 2.94121 -0.575031 5.62864 0.457903 7.90205L1.21299 9.56396L1.21413 9.56642Z"
                      fill="#32A771"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_88_1403">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="text-[16px] text-black">530-923-663</div>
              </div>
              <div className="flex flex-row gap-4">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_88_1408)">
                    <path
                      d="M12 15.6C10.0152 15.6 8.4 13.9848 8.4 12C8.4 10.0152 10.0152 8.4 12 8.4C13.9848 8.4 15.6 10.0152 15.6 12C15.6 13.9848 13.9848 15.6 12 15.6ZM12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C12.3182 24 12.6235 23.8735 12.8485 23.6485C13.0735 23.4235 13.2 23.1182 13.2 22.8C13.2 22.4818 13.0735 22.1765 12.8485 21.9515C12.6235 21.7265 12.3182 21.6 12 21.6C6.7068 21.6 2.4 17.2932 2.4 12C2.4 6.7068 6.7068 2.4 12 2.4C17.2932 2.4 21.6 6.7068 21.6 12V13.2C21.6 14.1924 20.7924 15 19.8 15C18.8076 15 18 14.1924 18 13.2V12C18 8.6916 15.3084 6 12 6C8.6916 6 6 8.6916 6 12C6 15.3084 8.6916 18 12 18C13.8372 18 15.4644 17.1516 16.566 15.846C16.9555 16.3292 17.448 16.7196 18.0074 16.9884C18.5669 17.2572 19.1792 17.3978 19.8 17.4C22.116 17.4 24 15.516 24 13.2V12C24 5.3832 18.6168 0 12 0Z"
                      fill="#32A771"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_88_1408">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="text-[16px] text-black">
                  Biuro@sellwell-nieruchomosci.pl
                </div>
              </div>
            </div>
            <div className="flex flex-row flex-wrap gap-4">
              <div className="text-[16px] text-black">Siedziba</div>
              <div className="w-full h-[1px] bg-[#E0E0E0]" />
              <div className="flex flex-row gap-4">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="min-h-[24px] min-w-[24px]"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 10.5C2.25 5.55833 6.61378 1.25 12 1.25C17.3862 1.25 21.75 5.55833 21.75 10.5C21.75 14.0529 19.5756 17.1115 17.3974 19.2256C16.2964 20.2942 15.1626 21.1531 14.2326 21.7486C13.768 22.0461 13.3455 22.2835 12.9957 22.4497C12.8214 22.5325 12.6555 22.6023 12.5051 22.6529C12.3718 22.6978 12.1879 22.7498 12 22.7498C11.8121 22.7498 11.6282 22.6978 11.4949 22.6529C11.3445 22.6023 11.1786 22.5325 11.0043 22.4497C10.6545 22.2835 10.232 22.0461 9.76744 21.7486C8.83739 21.1531 7.70362 20.2942 6.60265 19.2256C4.42441 17.1115 2.25 14.0529 2.25 10.5ZM12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z"
                    fill="#32A771"
                  />
                </svg>

                <div className="text-[16px] text-black">
                  SellWell Nieruchomości Sp. z o.o. z siedzibą w Łodzi 90-001
                  Ul. Piotrkowska 60 lok.1, wpisanym do Rejestru KRS
                  prowadzonego przez Sąd Rejonowy dla m.st. Łodzi XX Wydział
                  gospodarczy pod numerem KRS: 0000151116
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_88_1408)">
                    <path
                      d="M12 15.6C10.0152 15.6 8.4 13.9848 8.4 12C8.4 10.0152 10.0152 8.4 12 8.4C13.9848 8.4 15.6 10.0152 15.6 12C15.6 13.9848 13.9848 15.6 12 15.6ZM12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C12.3182 24 12.6235 23.8735 12.8485 23.6485C13.0735 23.4235 13.2 23.1182 13.2 22.8C13.2 22.4818 13.0735 22.1765 12.8485 21.9515C12.6235 21.7265 12.3182 21.6 12 21.6C6.7068 21.6 2.4 17.2932 2.4 12C2.4 6.7068 6.7068 2.4 12 2.4C17.2932 2.4 21.6 6.7068 21.6 12V13.2C21.6 14.1924 20.7924 15 19.8 15C18.8076 15 18 14.1924 18 13.2V12C18 8.6916 15.3084 6 12 6C8.6916 6 6 8.6916 6 12C6 15.3084 8.6916 18 12 18C13.8372 18 15.4644 17.1516 16.566 15.846C16.9555 16.3292 17.448 16.7196 18.0074 16.9884C18.5669 17.2572 19.1792 17.3978 19.8 17.4C22.116 17.4 24 15.516 24 13.2V12C24 5.3832 18.6168 0 12 0Z"
                      fill="#32A771"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_88_1408">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="text-[16px] text-black">NIP: 7282500130</div>
              </div>
              <div className="flex flex-row gap-4">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_88_1408)">
                    <path
                      d="M12 15.6C10.0152 15.6 8.4 13.9848 8.4 12C8.4 10.0152 10.0152 8.4 12 8.4C13.9848 8.4 15.6 10.0152 15.6 12C15.6 13.9848 13.9848 15.6 12 15.6ZM12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C12.3182 24 12.6235 23.8735 12.8485 23.6485C13.0735 23.4235 13.2 23.1182 13.2 22.8C13.2 22.4818 13.0735 22.1765 12.8485 21.9515C12.6235 21.7265 12.3182 21.6 12 21.6C6.7068 21.6 2.4 17.2932 2.4 12C2.4 6.7068 6.7068 2.4 12 2.4C17.2932 2.4 21.6 6.7068 21.6 12V13.2C21.6 14.1924 20.7924 15 19.8 15C18.8076 15 18 14.1924 18 13.2V12C18 8.6916 15.3084 6 12 6C8.6916 6 6 8.6916 6 12C6 15.3084 8.6916 18 12 18C13.8372 18 15.4644 17.1516 16.566 15.846C16.9555 16.3292 17.448 16.7196 18.0074 16.9884C18.5669 17.2572 19.1792 17.3978 19.8 17.4C22.116 17.4 24 15.516 24 13.2V12C24 5.3832 18.6168 0 12 0Z"
                      fill="#32A771"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_88_1408">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="text-[16px] text-black">REGON: 473192600</div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[1px] bg-[#E0E0E0] w-full" />
        <div className="flex flex-col md:flex-row gap-4 justify-between items-center">
          <div className="text-black text-[16px]">
            © {new Date().getFullYear()} — Copyright
          </div>
          <div className="text-black text-[16px]">Assembled by Askay</div>
          <div className="flex flex-row gap-4">
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_88_1380)">
                <path
                  d="M17.5 0C7.85054 0 0 7.85054 0 17.5C0 27.1488 7.85054 35 17.5 35C27.1488 35 35 27.1488 35 17.5C35 7.85054 27.1502 0 17.5 0ZM21.8521 18.1161H19.005V28.2639H14.7861C14.7861 28.2639 14.7861 22.7191 14.7861 18.1161H12.7807V14.5295H14.7861V12.2097C14.7861 10.5482 15.5756 7.95205 19.0437 7.95205L22.17 7.96403V11.4456C22.17 11.4456 20.2703 11.4456 19.9009 11.4456C19.5315 11.4456 19.0064 11.6302 19.0064 12.4226V14.5302H22.2208L21.8521 18.1161Z"
                  fill="#525252"
                />
              </g>
              <defs>
                <clipPath id="clip0_88_1380">
                  <rect width="35" height="35" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_88_1376)">
                <path
                  d="M20.8496 17.5C20.8496 19.35 19.35 20.8496 17.5 20.8496C15.65 20.8496 14.1504 19.35 14.1504 17.5C14.1504 15.65 15.65 14.1504 17.5 14.1504C19.35 14.1504 20.8496 15.65 20.8496 17.5Z"
                  fill="#525252"
                />
                <path
                  d="M25.3336 11.5734C25.1726 11.1371 24.9157 10.7421 24.582 10.418C24.2578 10.0842 23.8631 9.82729 23.4265 9.66627C23.0724 9.52875 22.5405 9.36506 21.5608 9.32047C20.501 9.27213 20.1832 9.26172 17.5001 9.26172C14.8167 9.26172 14.499 9.27187 13.4394 9.3202C12.4597 9.36506 11.9275 9.52875 11.5737 9.66627C11.1371 9.82729 10.7421 10.0842 10.4182 10.418C10.0844 10.7421 9.82755 11.1368 9.66627 11.5734C9.52875 11.9275 9.36506 12.4597 9.32047 13.4394C9.27213 14.499 9.26172 14.8167 9.26172 17.5001C9.26172 20.1832 9.27213 20.501 9.32047 21.5608C9.36506 22.5405 9.52875 23.0724 9.66627 23.4265C9.82755 23.8631 10.0842 24.2578 10.418 24.582C10.7421 24.9157 11.1368 25.1726 11.5734 25.3336C11.9275 25.4714 12.4597 25.6351 13.4394 25.6797C14.499 25.7281 14.8165 25.7382 17.4998 25.7382C20.1835 25.7382 20.5012 25.7281 21.5605 25.6797C22.5403 25.6351 23.0724 25.4714 23.4265 25.3336C24.3029 24.9956 24.9956 24.3029 25.3336 23.4265C25.4712 23.0724 25.6349 22.5405 25.6797 21.5608C25.7281 20.501 25.7382 20.1832 25.7382 17.5001C25.7382 14.8167 25.7281 14.499 25.6797 13.4394C25.6351 12.4597 25.4714 11.9275 25.3336 11.5734ZM17.5001 22.6599C14.6501 22.6599 12.3398 20.3498 12.3398 17.4998C12.3398 14.6498 14.6501 12.3398 17.5001 12.3398C20.3498 12.3398 22.6602 14.6498 22.6602 17.4998C22.6602 20.3498 20.3498 22.6599 17.5001 22.6599ZM22.8642 13.3417C22.1982 13.3417 21.6583 12.8017 21.6583 12.1357C21.6583 11.4698 22.1982 10.9298 22.8642 10.9298C23.5301 10.9298 24.0701 11.4698 24.0701 12.1357C24.0698 12.8017 23.5301 13.3417 22.8642 13.3417Z"
                  fill="#525252"
                />
                <path
                  d="M17.5 0C7.83649 0 0 7.83649 0 17.5C0 27.1635 7.83649 35 17.5 35C27.1635 35 35 27.1635 35 17.5C35 7.83649 27.1635 0 17.5 0ZM27.4882 21.643C27.4396 22.7127 27.2695 23.443 27.0212 24.0823C26.4991 25.4321 25.4321 26.4991 24.0823 27.0212C23.4433 27.2695 22.7127 27.4393 21.6432 27.4882C20.5716 27.5371 20.2293 27.5488 17.5003 27.5488C14.771 27.5488 14.4289 27.5371 13.357 27.4882C12.2876 27.4393 11.557 27.2695 10.918 27.0212C10.2472 26.7688 9.64001 26.3734 9.13799 25.862C8.6269 25.3603 8.23143 24.7528 7.97909 24.0823C7.73075 23.4433 7.56065 22.7127 7.51205 21.6432C7.46265 20.5714 7.45117 20.229 7.45117 17.5C7.45117 14.771 7.46265 14.4286 7.51179 13.357C7.56039 12.2873 7.73022 11.557 7.97855 10.9177C8.2309 10.2472 8.62663 9.63974 9.13799 9.13799C9.63974 8.62663 10.2472 8.23116 10.9177 7.97882C11.557 7.73048 12.2873 7.56065 13.357 7.51179C14.4286 7.46292 14.771 7.45117 17.5 7.45117C20.229 7.45117 20.5714 7.46292 21.643 7.51205C22.7127 7.56065 23.443 7.73048 24.0823 7.97855C24.7528 8.2309 25.3603 8.62663 25.8623 9.13799C26.3734 9.64001 26.7691 10.2472 27.0212 10.9177C27.2698 11.557 27.4396 12.2873 27.4885 13.357C27.5373 14.4286 27.5488 14.771 27.5488 17.5C27.5488 20.229 27.5373 20.5714 27.4882 21.643Z"
                  fill="#525252"
                />
              </g>
              <defs>
                <clipPath id="clip0_88_1376">
                  <rect width="35" height="35" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
